const handleAction = async (serviceCallParams, executeServiceCall, failureAlert, successAlertTexts, setDialogOpen, errorAlert, successAlert) => {
    try {
        const response = await executeServiceCall(serviceCallParams);
        if ('error' in response) {
            const _response = response;
            errorAlert.setError(failureAlert.title, _response.error.data.FriendlyMessage);
        }
        else {
            successAlert.setSuccess(successAlertTexts.title, successAlertTexts.message);
        }
    }
    catch (err) {
        errorAlert.setError(failureAlert.title, failureAlert.message);
    }
    finally {
        setDialogOpen(false);
    }
};

export { handleAction };
